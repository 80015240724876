// 画作模块
import request from "../utils/request";

// 获取画作列表详细
export const getIllustration = (seriesId) => {
  return request({
    method: "GET",
    url: "/illustration/app/" + seriesId,
    // params: {
    //   seriesId,
    // },
  });
};
