// 封装 axios
import axios from "axios";

const request = axios.create({
  // baseURL: import.meta.env.VITE_APP_URL + "/api",
  // baseURL: "http://localhost:3000/api",
  baseURL: "http://124.223.81.2:3000/api",
  // headers:{'Content-Type':'application/x-www-form-urlencoded'}
});

// 请求拦截器
request.interceptors.request.use({
  function(config) {
    // 请求发送，所经之处
    // config : 本次请求的 请求配置对象
    // const user = store.state.user;
    // if (user && user.myToken) {
    //   config.headers.Authorization = `Bearer ${user.myToken}`;
    // }

    // config.headers.set("Content-Type", "application/x-www-form-urlencoded");
    return config;
  },
  function(err) {
    // 如果请求出错了，会被执行
    return Promise.reject(err);
  },
});

// 响应拦截器

export default request;
