// 系列模块
import request from "../utils/request";

// 获取系列列表标题
export const getSeries = () => {
  return request({
    method: "GET",
    url: "/series/app",
  });
};
