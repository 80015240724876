<template>
  <v-container fluid class="home">
    <!-- 主 -->
    <v-container class="home-container d-flex justify-center">
      <!-- 左1 -->
      <!-- PC左侧栏 -->
      <v-card
        class="left-menu"
        max-width="200px"
        min-width="150px"
        v-if="!isMobile"
        tile
        flat
      >
        <v-list dense>
          <v-img
            height="80px"
            :src="site.logoUrl ? VITE_IMAGE_URL + site.logoUrl : ''"
            @click="clickHome"
          ></v-img>
          <!-- 第一栏 -->
          <v-list-group :ripple="false" no-action>
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title v-text="site.name"></v-list-item-title>
              </v-list-item-content>
            </template>

            <v-list-item :ripple="false" @click="clickIntroduce">
              <v-list-item-content>
                <v-list-item-title>关于</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item :ripple="false" @click="dialogWeibo = true">
              <v-list-item-content>
                <v-list-item-title>微博</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>

          <!-- 系列栏 -->
          <v-list-group
            :ripple="false"
            v-for="item in seriesList"
            :key="item._id"
            no-action
          >
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title v-text="item.name"></v-list-item-title>
              </v-list-item-content>
            </template>

            <v-list-item
              v-for="(child, index) in item.illustrations"
              :key="index"
              @click="clickItemSeries(item)"
              :ripple="false"
            >
              <v-list-item-content>
                <v-list-item-title
                  v-text="child.type + ' (' + child.count + ')'"
                ></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>

          <!-- 联系/工作 -->
          <v-list-item three-line>
            <v-list-item-content>
              <v-list-item-title>联系/工作</v-list-item-title>
              <v-list-item-subtitle>
                {{ site.contact }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <!-- 关于我们 -->
          <v-list-item three-line>
            <v-list-item-content>
              <v-list-item-title>关于我们</v-list-item-title>
              <v-list-item-subtitle>
                {{ site.about }}
              </v-list-item-subtitle>
              <v-list-item-subtitle>
                {{ webServeiceTime }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card>

      <!-- 主2 -A -->
      <!-- 轮播图 -->
      <v-card v-if="pageShow == 3" class="main-container" tile flat>
        <v-carousel
          v-model="model"
          fluid
          :height="imgWidth + 'px'"
          :style="styleImg"
          hide-delimiter-background
          hide-delimiters
          :show-arrows="false"
        >
          <v-carousel-item v-for="item in illustrationList" :key="item._id">
            <v-sheet :item="item" height="100%" tile>
              <v-row class="fill-height" align="center" justify="center">
                <v-card tile flat>
                  <v-img
                    :src="item.imageUrl ? VITE_IMAGE_URL + item.imageUrl : ''"
                    contain
                    :height="imgWidth"
                    :width="imgWidth"
                  ></v-img>
                </v-card>
              </v-row>
            </v-sheet>
          </v-carousel-item>
        </v-carousel>

        <!-- 主下1 -->
        <v-card class="main-footer-tab" tile flat>
          <!-- 手机 描述 -->
          <v-card class="describe" v-if="isMobile" tile flat>
            <div class="text-break text-caption">
              {{ illustrationList[model] ? illustrationList[model].name : "" }}
            </div>
            <p class="text-break text-caption">
              {{
                illustrationList[model] ? illustrationList[model].describe : ""
              }}
            </p>
          </v-card>

          <v-card class="btn-container justify-space-between" tile flat>
            <v-row justify="space-around" height="36px" :style="styleImg">
              <v-btn
                text
                elevation="0"
                :ripple="false"
                @click="model--"
                class="text-caption"
                >上一页</v-btn
              >
              <v-btn
                text
                elevation="0"
                :ripple="false"
                color="primary"
                @click="model++"
                class="text-caption"
                >下一页</v-btn
              >
            </v-row>
          </v-card>
        </v-card>
      </v-card>
      <!-- 主2 -B -->
      <!-- 个人介绍页 -->
      <v-card
        v-else-if="pageShow == 2"
        :width="imgWidth"
        class="main-introduce"
        tile
        flat
      >
        <!-- <p>个人介绍页</p> -->
        <p
          v-if="isMobile"
          class="text-caption"
          style="white-space: pre-wrap; width: 95%"
          v-html="site.introduce"
        ></p>
        <p
          v-else
          class="text-caption"
          style="white-space: pre-wrap; width: 70%"
          v-html="site.introduce"
        ></p>
      </v-card>
      <!-- 主2 -C -->
      <!-- 默认首页 -->
      <v-card v-else tile flat class="main-home">
        <v-img
          :src="site.homeUrl ? VITE_IMAGE_URL + site.homeUrl : ''"
          contain
          :height="imgWidth"
          :width="imgWidth"
        ></v-img>
      </v-card>

      <!-- 右3 -->
      <!-- PC 描述 -->
      <v-card
        v-if="!isMobile"
        class="right-container describe d-flex align-center"
        tile
        flat
        :height="imgWidth + 'px'"
        max-width="200px"
        min-width="200px"
      >
        <p
          class="text-break text-caption"
          style="max-width: 200px"
          v-if="!isMobile && pageShow == 3"
        >
          {{ illustrationList[model] ? illustrationList[model].name : "" }}
          <br />
          {{ illustrationList[model] ? illustrationList[model].describe : "" }}
        </p>
      </v-card>
    </v-container>

    <!-- 下 -->
    <!-- 手机 菜单、首页 按钮 -->
    <v-card
      class="footer-mobile-tab d-flex justify-center"
      height="56px"
      v-if="isMobile"
      tile
      flat
    >
      <v-btn
        class="mx-2 box_relative_mobile_home_btn_child"
        fab
        @click.stop="drawerMobileMenu = !drawerMobileMenu"
      >
        <v-icon dark class="mid mdi-view-headline"> mdi-view-headline </v-icon>
      </v-btn>
      <v-divider vertical></v-divider>
      <v-btn class="mx-2" fab @click.stop="clickHome">
        <v-icon dark class="mid mdi-home">mdi-home </v-icon>
      </v-btn>
    </v-card>

    <!-- 手机侧栏、竖屏侧栏 -->
    <v-navigation-drawer
      v-model="drawerMobileMenu"
      absolute
      temporary
      v-if="isMobile"
    >
      <v-list dense>
        <v-img
          height="80px"
          :src="site.logoUrl ? VITE_IMAGE_URL + site.logoUrl : ''"
          @click="clickHome"
        ></v-img>
        <!-- 第一栏 -->
        <v-list-group :ripple="false" no-action>
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title v-text="site.name"></v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item :ripple="false" @click="clickIntroduce">
            <v-list-item-content>
              <v-list-item-title>关于</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item :ripple="false" @click="dialogWeibo = true">
            <v-list-item-content>
              <v-list-item-title>微博</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>

        <!-- 系列栏 -->
        <v-list-group
          :ripple="false"
          v-for="item in seriesList"
          :key="item._id"
          no-action
        >
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title v-text="item.name"></v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item
            v-for="(child, index) in item.illustrations"
            :key="index"
            @click="clickItemSeries(item)"
            :ripple="false"
          >
            <v-list-item-content>
              <v-list-item-title
                v-text="child.type + ' (' + child.count + ')'"
              ></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>

        <!-- 联系/工作 -->
        <v-list-item three-line>
          <v-list-item-content>
            <v-list-item-title>联系/工作</v-list-item-title>
            <v-list-item-subtitle>
              {{ site.contact }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <!-- 关于我们 -->
        <v-list-item three-line>
          <v-list-item-content>
            <v-list-item-title>关于我们</v-list-item-title>
            <v-list-item-subtitle>
              {{ site.about }}
            </v-list-item-subtitle>
            <v-list-item-subtitle>
              {{ webServeiceTime }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <!-- 微博跳转提示 对话框 -->
    <v-dialog v-model="dialogWeibo" max-width="290">
      <v-card>
        <v-card-title class="text-h5"> {{ site.name }}的微博主页 </v-card-title>

        <v-card-text>
          您将打开{{ site.name }}的微博主页，链接为：
          {{ site.weibo }}
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn :ripple="false" color="gray" text @click="dialogWeibo = false">
            返回
          </v-btn>

          <v-btn :ripple="false" color="blue" text @click="clickWeibo">
            确认
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- 备案信息 -->
    <v-card class="d-flex box_relative_beian" tile flat v-if="pageShow == 1">
      <v-card-text
        class="text--disabled text-right box_relative_beian_child"
        @click="clickGongXinBeian"
      >
        {{ beianGongXin }}
      </v-card-text>

      <v-card-text
        class="text--disabled text-left box_relative_beian_child"
        @click="clickGongAnBeian"
      >
        {{ beianGongAn }}
      </v-card-text>
    </v-card>

  </v-container>
</template>

<script>
import { mapGetters } from "vuex";

import { getIllustration } from "../api/illustration";
import { getSeries } from "../api/series";
import { getSite } from "../api/site";

export default {
  name: "Home",

  components: {},

  data() {
    return {
      // 图片服务器
      VITE_IMAGE_URL: "http://124.223.81.2:3000/",

      // carousel 的选中 绑定对象
      model: 0,

      // 页面宽度
      fullWidth: window.screen.width,
      // 页面高度
      fullHeight: window.screen.height,

      // 根据页面长和宽，确定;是否是手机（是：手机竖屏，ipad 竖屏；否：电脑、手机、ipad的横屏）
      isMobile: false,

      // imgWidth: 0, // 最大 960px 高度为宽度的3/4; 手机上是全屏

      // 手机
      drawerMobileMenu: false,

      // 网站信息
      site: {
        logoUrl: "",
        name: "",
        introduce: "",
        weibo: "",
        about: "",
        contact: "",
        homeUrl: "",
        introduces: [], // key:introduce 和 weibo;value:''
      },
      // 系列列表：_id,name,illustrations:[{type,count}],location,
      seriesList: [],
      // 画作列表：_id,imageUrl,name,describe,location
      illustrationList: [],

      // 显示 1：主页，2：介绍页，3：系列 。默认是：1：主页
      pageShow: 1,

      // 微博 跳转 对话框
      dialogWeibo: false,

      // 备案 跳转 对话框
      dialogBeian: false,

      // 工信部备案 字符串
      beianGongXin: "鄂ICP备2022001799号-1",

      // 网公安备案 字符串
      beianGongAn: "鄂公网安备42028102000196号",
      // beianStr: `<div style="width:300px;margin:0 auto; padding:20px 0;">
      // 	<a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=42028102000196" style="display:inline-block;text-decoration:none;height:20px;line-height:20px;"><img src="" style="float:left;"/><p style="float:left;height:20px;line-height:20px;margin: 0px 0px 0px 5px; color:#939393;">鄂公网安备 42028102000196号</p></a>
      // </div>`,

      // 网站服务时间
      webServeiceTime: "web：9am-9pm",
    };
  },

  computed: {
    ...mapGetters(["isCollaspe"]),
    rightWidth() {
      let leftWidth = this.isCollaspe ? "64" : "200";
      return this.fullWidth - leftWidth + "px";
    },
    styleImg() {
      let str = "width:" + this.imgWidth + "px !important;";
      return str;
    },

    imgWidth() {
      let twidth = 0;

      if (this.fullWidth > this.fullHeight) {
        // 横屏
        twidth = (this.fullHeight / 3) * 2;
        this.isMobile = false;
      } else {
        // 竖屏
        twidth = this.fullWidth;
        this.isMobile = true;
      }
      return twidth;
    },
  },

  methods: {
    // 网站信息
    async getSiteInfo() {
      const { data } = await getSite();

      let siteInfo = data.data[0];

      let introduce = {
        key: "关于",
        value: siteInfo.introduce,
      };
      let weibo = {
        key: "微博",
        value: siteInfo.weibo,
      };
      let introduces = [introduce, weibo];
      siteInfo.introduces = introduces;
      this.site = siteInfo;
    },

    // 系列信息
    async getSeriesInfo() {
      const { data } = await getSeries();

      this.seriesList = data.data;
    },

    // 图片信息
    async getIllstrationInfo(seriesId) {
      const { data } = await getIllustration(seriesId);

      this.illustrationList = data.data;
    },

    // 左侧栏
    leftOpen(val) {
      // 只有手机才能关闭
      this.drawerMobileMenu = val;
    },

    // 主页
    clickHome() {
      this.pageShow = 1;
      this.leftOpen(false);
    },

    // 个人简介
    clickIntroduce() {
      // console.log("介绍");
      this.pageShow = 2;
      this.leftOpen(false);
    },

    // 微博
    clickWeibo() {
      // window.location.href = this.site.weibo; // 本窗口
      window.open(this.site.weibo, "_blank"); // 新窗口
    },

    // 系列
    clickItemSeries(item) {
      this.pageShow = 3;
      // 图片信息
      this.getIllstrationInfo(item._id);
      this.leftOpen(false);
    },

    // 自适应计算
    handleResize(event) {
      this.fullWidth = window.screen.width;
      this.fullHeight = window.screen.height;
    },

    // 工信部备案
    clickGongXinBeian() {
      window.open("https://beian.miit.gov.cn/");
    },

    // 网公安备案
    clickGongAnBeian() {
      window.open(
        "http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=42028102000196"
      );
    },
  },

  beforeCreate() {
    this.fullWidth = window.screen.width;
    this.fullHeight = window.screen.height;
  },

  created() {
    window.addEventListener("resize", this.handleResize);

    // 网站信息
    this.getSiteInfo();
    // 系列信息
    this.getSeriesInfo();
  },

  beforeDestroy() {
    window.removeEventListener("resize", this.handleResize);
  },
};
</script>

<style scoped>
.home {
  width: 100%;
  height: 100%;
  padding: 0;
}

.home-container {
  padding: 0;
}

.main-footer-tab .btn-container {
  margin-top: 20px;
}
.footer-mobile-tab {
  margin-top: 50px;
}

.describe {
  padding-left: 8px;
  padding-right: 8px;
}
.main-introduce {
  padding-top: 60px;
  padding-left: 20px;
}

.box_relative_beian {
  text-align: center;
  bottom: -150px;
  margin: 0 auto;
}
.box_relative_beian_child {
  display: inline-block;
  font-size: 6px;
}
</style>
