// 网站信息模块
import request from "../utils/request";

// 获取网站信息
export const getSite = () => {
  return request({
    method: "GET",
    url: "/site/app",
  });
};
